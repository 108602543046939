<template>
    <section class="almacen-mantenimiento px-3 container-fluid">
        <titulo-divisor titulo="Lista de materiales">
            <div class="row">
                <div class="col auto">
                    <el-input v-model="filter.buscar" prefix-icon="el-icon-search" placeholder="Buscar material..." size="small" @keyup.native.enter="filtrarMateriales(false)" />
                </div>
                <div v-if="filtersCount == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="filtersCount > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">{{filtersCount}}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :usarServidor="true" :usarPaginacion="true" :servidorData="materials" @paginar="listarPag">
            <el-table-column label="Ref" prop="referencia" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.referencia }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="nombre">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos cr-pointer f-600" @click="verInformacionMaterial(scope.row.id)">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="110" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo_material.tipo_material }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M." prop="unidad_medida" width="110" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.unidad_medida.unidad_medida }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor cotizado" prop="valor" align="center" width="140">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Planes de mantenimiento" prop="cantPlanes" align="center" width="210">
                <template slot-scope="scope">
                        <div class="bg-general wh-30 br-4 mx-auto text-white d-middle-center">
                            {{ scope.row.numero_planes_mantenimiento ? scope.row.numero_planes_mantenimiento: '0' }}
                        </div>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-4">
                    <p class="input-label-top">Nombre de material</p>
                    <el-input v-model="filter.nombre" @keypress.native.enter="filtrarMateriales" placeholder="Nombre material" size="small" class="w-100" />
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filter.unidad_medida" clearable filterable placeholder="Seleccionar unidad de medida" size="small" class="w-100">
                        <el-option v-for="item in measure_units" :key="item.id" :label="`${item.unidad_medida} (${item.sigla})`" :value="item.id" />
                    </el-select>
                </div>
                 <div class="col-11 my-4">
                    <p class="input-label-top">Número de planes de mantenimiento</p>
                    <el-input v-model="filter.numero_planes_mantenimiento" @keypress.native.enter="filtrarMateriales" clearable placeholder="Número de planes de mantenimiento" size="small" class="w-100" />
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="maintenanceRange" ref="rangeValorCotizado" range :min="range[0]" :max="range[1]" :format-tooltip="formatTooltipRange" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getRange } from '~/services/global'
export default {
    data(){
        return{
            buscarListado: '',
            inputNombreMaterial: '',
            inputCantPlanes: '',
            valueSelect: '',
            range: [0, 0],
        }
    },
    computed: {
        ...mapGetters({
            materials: 'almacen/mantenimiento/listMaterialesMantenimientos',
            measure_units: 'selects/selects/select_measure_units',
        }),
        filter: {
            get(){
                return this.$store.getters['almacen/mantenimiento/filter']
            },
            set(val){
                this.$store.commit('almacen/mantenimiento/set_filter', val)
            }
        },
        maintenanceRange:{
            get(){
                return [this.filter.valor_inicial, this.filter.valor_final]
            },
            set(val){
                this.filter.valor_inicial = val[0]
                this.filter.valor_final = val[1]
            }
        },
        filtersCount(){
            return this.filter.count()
        },
        disableSlider(){
            return this.range.every(el => el === 0)
        }
    },
    methods:{
        ...mapActions({
            obtenerMateriales: 'almacen/mantenimiento/Action_get_materiales_mantenimientos',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            clearFilter: 'almacen/mantenimiento/clear_filter',
            getSelect: 'selects/selects/sync'
        }),

        verInformacionMaterial(id_material){
            this.$router.push({ name: 'almacen.materiales.ver.mantenimiento', params: {id: id_material} })
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async filtrarMateriales(busquedaInput){
            if(busquedaInput) this.$refs.abrirModalFiltros.toggle()
            await this.obtenerMateriales({ filtros: this.filter.clean() })
        },
        limpiarFiltro(){
            this.clearFilter();
            this.obtenerMateriales()
        },
        async listarPag(pag){
            await this.obtenerMateriales({ filtros: this.filter.clean(), pag })
        }
    },
    async mounted(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.mantenimiento' ]);
        this.getSelect(['Action_get_select_measure_units']);
        await this.obtenerMateriales()
        this.range = getRange(this.materials.data, 'valor')
    }
}
</script>
<style lang="scss" scoped>
</style>